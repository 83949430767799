import Vue from 'vue';

export default {
    consultarPTU(parametros) {
        return Vue.axios.post("calculo-nomina/calcular-ptu", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    findAnios(parametros) {
        return Vue.axios.get("anio-fiscal/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("calculo-nomina/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPeriodoIncidencia(id) {
        return Vue.axios.get("periodo-incidencias/periodo/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    editarPTU(parametros) {
        return Vue.axios.put("periodos/fecha_pago", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}