<template>
    <div class="pa-0 ma-0">
        <tr :class="clasetr">
            <td class="justify-start tdDescripcion">
                <div class="">
                    {{item.tipo_nomina_info.clave}} - {{item.tipo_nomina_info.descripcion}}
                </div>
            </td>
            <td style="min-width:79px!important; max-width:81px!important; width:80px; padding-left:8px;">
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                                <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>
        <template v-if="isOpen">
            <v-row class="pa-0 ma-0">
                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0 px-0">
                    <table class="elevation-0">
                        <tbody>
                            <template v-for="(item,index) in empleados">
                                <ExpandableItemEmpleado
                                    :item="item"
                                    :key="index" 
                                >
                                </ExpandableItemEmpleado>
                            </template>
                        </tbody>
                    </table>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>
import GetUrlImg from "@/plugins/Urlimagen";
import ExpandableItemEmpleado from '@/components/ExpandableItemPTUEmpleado.vue';

export default {
    name: 'expandable-item',
    components: {
        ExpandableItemEmpleado
    },
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
        indexNom:{
            type: Number,
            required: false,
            default: null
        },
        numNominas:{
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isOpen: false,
            isOpen2:false,
            urlImg : '',
        }
    },
    /**
    * @description COMPUTED: Las funciones de este tipo se tratan como variables reactivas y sirven para que la misma variable haga 
    * una funcion para formatar los datos y regresar un nuevo valor en vez de crear funciones noramles y 
    * tener que llamarlas. Estas apenas se asignen se ejecutaran y ellas mismas regrsaran el nuevo resultado.
    */
    computed: {
        empleados(){
            let empleados = JSON.parse(JSON.stringify(this.item.data));
            return empleados;
        },
        clasetr(){
            if(this.indexNom==0){
                return 'borderTopTR'
            }
            else{
                return ''
            }
            
        }
    },
    methods: {
        dateFormat(fecha, tipo) {
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
    },
    mounted () {
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    /**
    * TRANSITION EFFECT
    */
    .slide-enter-active,
    .slide-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-enter,
    .slide-leave-active {
        opacity: 0
    }

    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: transparent;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    
    td > .loading{
        max-width: 100% !important;
    }
    tr {
      display:table;
      table-layout:fixed;
      background-color: #F6F6F6;
      width: 100%;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
    }
    .tdDescripcion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
    }
    .borderOneTR{
        border-radius: 20px
    }
    .borderTopTR{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    
</style>