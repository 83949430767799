<template>
    <div>
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container xl fluid style="background:transparent;">
            <v-row class="pa-0 ma-0">
                <v-col class="ma-0 pa-0" cols="12" xs="12" sm="9" md="9" lg="10" xl="10">
                    
                    <v-tabs v-model="tab" background-color="transparent" class="ml-1 my-0 pa-0">
                        <hr class="lineCliker"/>
                        
                        <v-tabs-slider color="transparent"></v-tabs-slider>
                        <v-tab class="pa-0 " >
                            <span :class="tab==1?'tabTextDisable':'tabText'" class="tabText mx-2">{{ 'Configurar PTU' }}</span>
                        </v-tab>
                        <v-tab  class="pa-0 " >
                            <span :class="tab==0?'tabTextDisable':'tabText'" class="tabText mx-2">{{ 'Nómina de PTU activas' }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>
                <!-- <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="2" class="pa-0 ma-0 d-flex justify-end"> 
                    <v-btn
                        v-if="tab==1"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 ml-3 mr-3 btnBuscar"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">mdi-magnify</v-icon>
                    </v-btn>
                </v-col> -->
            </v-row>

            <v-col class="ma-0 pa-0 pl-3" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="background:transparent;">
                <v-tabs-items v-model="tab" class="mb-10" style="background:transparent;">
                    <!-- *********************** -->
                    <!-- *********************** -->
                    <!-- TAB Configurar PTU -->
                    <!-- *********************** -->
                    <!-- *********************** -->
                    <v-tab-item :key="0" style="background:#EFEFEF;">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-card style="border-radius: 20px">
                                    <ValidationObserver ref="form" v-slot="{ invalid }">
                                        <v-card-text class="px-30px pt-30px">
                                            <v-row class="py-0 my-0">
                                                <template v-if="rol == 'root'">
                                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                            <v-autocomplete
                                                                outlined
                                                                label="Cliente"
                                                                :items="clientes"
                                                                item-text="nombre"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="cliente_value"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" >
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </template>
                                                <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Empresa"
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            required 
                                                            :error-messages="errors" 
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Año fiscal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Año fiscal"
                                                            :items="itemsAnios"
                                                            item-text="anio.nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="anio_id"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6" class="py-0">
                                                    <VuetifyMoneyValidate
                                                        rules="required"
                                                        outlined
                                                        label="Tope de ingreso"
                                                        class="pa-0 ma-0"
                                                        prefix="$"
                                                        v-model="topeIngreso"
                                                        v-bind:options="options"
                                                        v-bind:showAppendOuter="true"
                                                        v-bind:textTooltip="'El valor ingresado será el tope para el monto de los <br>salarios devengados durante el ejercicio, que será el <br>20% de mas del salario de un trabajador sindicalizado <br>o de planta. Fundamentado Artículo 127 Fracción II LFT.'"
                                                        ref="topeIngreso"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6" class="py-0">
                                                    <VuetifyMoneyValidate
                                                        rules="required"
                                                        outlined
                                                        label="Importe a repartir"
                                                        class="pa-0 ma-0"
                                                        prefix="$"
                                                        v-model="importeRepartir"
                                                        v-bind:options="options"
                                                        ref="importeRepartir"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        rules="required"
                                                        label="Fecha de pago"
                                                        :valorDefecto="fechaPago"
                                                        v-model="fechaPago"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaPago"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Complemento de PTU" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Complemento de PTU"
                                                            :items="itemsComplementos"
                                                            item-text="nombre"
                                                            item-value="value"
                                                            persistent-hint
                                                            v-model="tipo_complemento"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <v-divider></v-divider>
                                                    <p class="mt-3 subtitle-divider">Personal que no participara en el reparto de utilidades</p>
                                                </v-col>
                                                
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Puestos"
                                                        :items="itemsPuestos"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="arrayPuestos"
                                                        multiple
                                                    >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item
                                                                ripple
                                                                class="py-0 my-0"
                                                                @click="toggleAllPuestos"
                                                            >
                                                                <v-list-item-action>
                                                                    <v-icon :color="arrayPuestos.length > 0 ? 'indigo darken-4' : ''">
                                                                        {{ icon }}
                                                                    </v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        Seleccionar todos
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                        </template>
                                                        <template v-slot:selection="{ item, index }" >
                                                            <v-chip v-if="index === 0">
                                                                <span>{{ item.nombre }}</span>
                                                            </v-chip>
                                                            <v-chip v-if="index === 1">
                                                                <span>{{ item.nombre }}</span>
                                                            </v-chip>
                                                            <span
                                                                v-if="index === 2"
                                                                class="grey--text caption ml-1"
                                                            >
                                                                (+{{ arrayPuestos.length - 2 }} otros)
                                                            </span>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex align-center text-center text-laborados">
                                                    <p class="mb-0">Trabajadores con menos de </p>
                                                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            hide-details
                                                            v-model="dias_minimo_laborados"
                                                            v-mask="'####'"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <p class="mb-0"> días laborados</p>
                                                </v-col>
                                                <!--  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                                    <v-container fluid class="py-0">
                                                        <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="empleados_menor_60_dias" label="Trabajadores eventuales con menos de 60 días laborados"></v-checkbox>
                                                    </v-container>
                                                </v-col> -->
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                                    <v-container fluid class="py-0 d-flex">
                                                        <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="soloAcumulado" label="Calcular únicamente empleados acumulados"></v-checkbox>
                                                        <v-tooltip top class="pb-7">
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                            </template>
                                                            <span class="pb-7 textTooltip">
                                                                En caso de no seleccionar esta opción, solo se <br>
                                                                calcularán los empleados timbrados.
                                                            </span>
                                                        </v-tooltip>
                                                    </v-container>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                                    <v-container fluid class="py-0 d-flex">
                                                        <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="incluir_vacaciones_pagadas" label="No incluir vacaciones pagadas"></v-checkbox>
                                                        <v-tooltip top class="pb-7">
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                            </template>
                                                            <span class="pb-7 textTooltip">
                                                                En caso de no seleccionar esta opción, el sistema <br>
                                                                incluirá las vacaciones pagadas en el monto de los <br>
                                                                ingresos percibidos durante el año del cálculo de <br>
                                                                Reparto de utilidades.
                                                            </span>
                                                        </v-tooltip>
                                                    </v-container>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                                    <v-container fluid class="py-0 d-flex">
                                                        <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="topar_ptu" label="Topar PTU"></v-checkbox>
                                                        <v-tooltip top class="pb-7">
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                            </template>
                                                            <span class="pb-7 textTooltip">
                                                                Configuración de tope de PTU a 3 meses de salario o <br>
                                                                promedio de PTU recibida los últimos 3 años.
                                                            </span>
                                                        </v-tooltip>
                                                    </v-container>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="d-flex justify-end pa-30px">
                                            <v-btn
                                                class="btnGuardar"
                                                @click="consultar()"
                                                :loading="isLoadingConsultar"
                                                :disabled="invalid" 
                                            >
                                                Consultar
                                            </v-btn>
                                        </v-card-actions>
                                    </ValidationObserver>
                                </v-card>
                            </v-col>

                            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                                <v-card style="border-radius: 20px">
                                    <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                                        <div class="align-center justify-center text-center">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="#2DB9D1"
                                            >
                                                <v-img
                                                    src="/static/icon/favicon.ico"
                                                    :width="60"
                                                ></v-img>
                                            </v-progress-circular>
                                            <p class="mt-5">Cargando...</p>
                                        </div>
                                    </v-overlay>
                                    <v-card-text class="px-30px pt-30px pb-0">
                                        <v-btn
                                            color="#FFF"
                                            class="elevation-0 mr-13 mt-13 pt-0 pb-0 pl-0 pr-0"
                                            absolute
                                            top
                                            right
                                            @click="reporteConsulta()"
                                            height="52"
                                            width="20"
                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                            :disabled="btnGuardar" 
                                        >
                                            <img src="/static/icon/xls.png" style="width:30px"/>
                                        </v-btn>
                                        <v-row class="pa-0 ma-0 informacionGeneral">
                                            <v-row class="px-9 pt-12 pb-7">
                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                    <p class="informacionGeneralTitulo">Información nómina PTU</p>
                                                </v-col> 
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0" >
                                                    <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                    <p class="informacionGeneralText">{{ptuEmpresa ? ptuEmpresa:"--"}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Año Fiscal:</p>
                                                    <p class="informacionGeneralText">{{ptuAnio ? ptuAnio:"--"}}</p>
                                                </v-col>
                                                
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Tope de ingreso:</p>
                                                    <p class="informacionGeneralText">{{formatNum(ptuTope)}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Importe a repartir:</p>
                                                    <p class="informacionGeneralText">{{formatNum(ptuImporte)}}</p>
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions class="d-flex py-0 my-0 pt-4 justify-end px-30px">
                                        <v-btn
                                            class="btnGuardar"
                                            @click="guardar()" 
                                            :disabled="btnGuardar" 
                                            :loading="isSaving"
                                        >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                    <v-card-text class="px-30px pb-30px" style="position:relative;">
                                        <v-row class="pa-0 ma-0 areaConsulta informacionGeneralConsulta" style="border-radius: 20px">
                                            <v-container class="pa-0" style="border-radius: 20px">
                                                <v-col 
                                                    cols="12" xs="12" sm="12" md="12" 
                                                    class="pa-0 " 
                                                    :class="showDatos ? '':'d-flex justify-center align-center text-center'"
                                                    :style="showDatos ? 'background: #FFF; min-height:500px;':'background: #F6F6F6; min-height:500px; '"
                                                    
                                                >
                                                    <p v-if="!showDatos">No hay elementos para mostrar</p>
                                                    <div v-else>
                                                        <table>
                                                            <tbody>
                                                                <template v-for="(item,index) in itemsNominas">
                                                                    <expandable-item 
                                                                        :item="item"
                                                                        :key="index" 
                                                                        :indexNom="index"
                                                                        :numNominas="itemsNominas.length"
                                                                    >
                                                                    </expandable-item>
                                                                    <hr :key="'a'+index" class="trBody" v-if="index < itemsNominas.length - 1" />
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </v-col>
                                            </v-container>
                                        
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>




                    <!-- *********************** -->
                    <!-- *********************** -->
                    <!-- TAB Nómina de PTU activas -->
                    <!-- *********************** -->
                    <!-- *********************** -->
                    <v-tab-item :key="1" style="background:transparent;">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" lg="12">
                                <data-table
                                    ref="tablaNomina"
                                    :url="url"
                                    :columns="columns"
                                    :filters="filters"
                                    :perPage="[10,25,50,100]"
                                    :showAdvancedFilters="false"
                                >
                                    <tbody slot="body" slot-scope="{ data }">
                                        <tr :key="index" v-for="(item, index) in data">
                                            <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}} </div></td>
                                            <td>
                                            
                                                <div>
                                                    <v-menu v-model="item.menu" buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesItem menu-width"> 
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="transparent"
                                                                rounded
                                                                class="elevation-0 v-btn-periodo"
                                                                :ripple="false" 
                                                            >
                                                                <span class="span-periodo">{{item.numero_periodo}}</span>
                                                            </v-btn>
                                                        </template>

                                                        <v-list>
                                                            <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                                <v-btn
                                                                    @click="item.menu = false"
                                                                    small
                                                                    icon
                                                                    text
                                                                    :ripple="false" 
                                                                    class="v-btn-cerrar mr-2"
                                                                >
                                                                    <v-icon class="icono-cerrar"></v-icon>
                                                                </v-btn>
                                                            </div>
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle>
                                                                        <v-row class="pa-0 ma-0">
                                                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                                <p class="informacion-general-titulo">Información del PTU</p>
                                                                            </v-col> 
                                                                            <v-col cols="12" xs="6" sm="6" md="6" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                                                    <p class="informacionGeneralText text-wrap">{{item.nombre_empresa ? item.nombre_empresa:"Información faltante*"}}</p>
                                                                                </v-col>
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                                                    <p class="informacionGeneralText">{{item.mes_de_acomulacion}}</p>
                                                                                </v-col>
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha de pago:</p>
                                                                                    <p class="informacionGeneralText text-wrap">{{item.fecha_de_pago}}</p>
                                                                                </v-col>
                                                                            </v-col>

                                                                            <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha inicial:</p>
                                                                                    <p class="informacionGeneralText">{{dateFormat(item.fecha_inicial, 'modal')}}</p>
                                                                                </v-col>
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha final:</p>
                                                                                    <p class="informacionGeneralText">{{dateFormat(item.fecha_final, 'modal')}}</p>
                                                                                </v-col>
                                                                                
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <p class="mb-0 informacionGeneralSubtitle">Días de pago:</p>
                                                                                    <p class="informacionGeneralText">{{item.dias_de_pago}}</p>
                                                                                </v-col>
                                                                            </v-col>
                                                                        </v-row>                           
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </div>
                                            
                                            
                                            
                                            </td>
                                            <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                            <td>
                                                <div class="tblOpciones align-center">
                                                    
                                                    <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                icon 
                                                                fab
                                                                dark
                                                                small
                                                                class="elevation-0"
                                                            >
                                                                <template  v-for="(item,valor) in attrs" >
                                                                    <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                    <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                </template>
                                                            </v-btn>
                                                        </template>

                                                        <v-list>
                                                            <v-list-item
                                                                @click="abrirModalRecalcular(item)"
                                                            >
                                                                <v-list-item-title>Recalcular</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                                v-if="!item.requiere_recalcular"
                                                                @click="abrirModalTimbre(item)"
                                                            >
                                                                <v-list-item-title>Acumular</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                                @click="abrirModalEditar(item)"
                                                            >
                                                                <v-list-item-title>Editar</v-list-item-title>
                                                            </v-list-item>
                                                            
                                                            <v-list-item
                                                                @click="reporte(item)" 
                                                                v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"    
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                                @click="detallePTU(item)"
                                                            >
                                                                <v-list-item-title>Detalle de PTU</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                                @click="complementos(item)"
                                                            >
                                                                <v-list-item-title>Complementos</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn 
                                                                v-on="on" 
                                                                @click="eliminar(item)" 
                                                                class="botonHover" 
                                                                icon 
                                                                fab 
                                                                dark 
                                                                small
                                                            >
                                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Eliminar</span>
                                                    </v-tooltip>

                                                    <v-tooltip top v-if="item.requiere_recalcular">
                                                        <template v-slot:activator="{ on }">
                                                            <v-img
                                                                v-on="on"
                                                                class="mr-n5"
                                                                style="margin-bottom:2px"
                                                                max-height="23"
                                                                max-width="23"
                                                                src="/static/svg/signoExclamacion.svg"
                                                            ></v-img>
                                                        </template>
                                                        <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                                    </v-tooltip>



                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </data-table>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>

            <template class="borde-card">
                <v-dialog v-model="dialogTimbre" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img :src="imagen" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        ¿Estás seguro que deseas acumular y timbrar el cálculo? No se podrá realizar ningún movimiento posteriormente.
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('acumular')"
                                        >
                                            Acumular
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('timbrar')"
                                        >
                                            Acumular y timbrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="cerrarModal()"
                                            text
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogRecalcular" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <h2 class="titleModalTimbre pb-5">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#1E2245'
                                            dark
                                            @click="recalcular()"
                                        >
                                            Recalcular
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#1E2245'
                                            dark
                                            @click="abrirModalReconsultar()"
                                        >
                                            Consultar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="cerrarModal()"
                                            text
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogReconsulta" persistent max-width="700px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalRecalculo"></v-btn>
                    </template>
                    
                        <v-card class="borde-card card_titulo">
                            <perfect-scrollbar>
                            <v-card-title class="justify-center">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="pt-0">
                                <div id="padre"  v-show="isLoadingReconsulta">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="justify-center align-center text-center">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </v-col>
                                </div>
                                
                                <v-container v-show="!isLoadingReconsulta" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row class="pa-0 ma-0 informacionGeneral">
                                        <v-row class="px-9 pt-12 pb-7">
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <p class="informacionGeneralTitulo">Información global</p>
                                            </v-col> 
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                            </v-col>
                                           
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">

                                        <table class="elevation-0 mt-4">
                                            <thead class="tbl-header-reconsulta">
                                                <tr>
                                                    <th class="th-reconsulta">
                                                        Empleados
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(item,index) in itemsEmpleadosReconsulta">
                                                    <ExpandableItemEmpleado
                                                        :item="item"
                                                        :key="index" 
                                                    >
                                                    </ExpandableItemEmpleado>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-row>  
                                </v-container>
                            </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" :disabled="isLoadingReconsulta" @click="cerrarModalReconsulta()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </template>
            
            <template class="borde-card">
                <v-dialog v-model="dialogEditar" persistent max-width="500px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalEditar"></v-btn>
                    </template>
                        <perfect-scrollbar style="background: #fff">
                            <v-card class="borde-card card_titulo">
                                <ValidationObserver ref="formEditar" v-slot="{ invalid }">
                                    <v-card-title class="card_texto">
                                        <div>
                                            <h2 class="title-modal-editar">{{ tituloModal }}</h2>
                                        </div>
                                    </v-card-title>

                                    <v-card-text class="pt-0">
                                        <div id="padre"  v-show="isLoadingEditar">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="justify-center align-center text-center">
                                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                            </v-col>
                                        </div>
                                        
                                        <v-container v-show="!isLoadingEditar" grid-list-md id="contenedor">
                                            <ValidationProvider v-slot="{ errors }" name="Mes de acumulación" rules="required">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Mes de acumulación"
                                                        :items="meses"
                                                        item-text="nombre"
                                                        item-value="nombre"
                                                        persistent-hint
                                                        v-model="mes_de_acomulacion"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                            </ValidationProvider>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    v-model="fecha_de_pago"
                                                    :valorDefecto="fecha_de_pago"
                                                    label="Fecha de pago"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaPago"
                                                    rules="required"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    v-model="itemPTU.fecha_inicial"
                                                    :valorDefecto="itemPTU.fecha_inicial"
                                                    label="Fecha inicial"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicial"
                                                    rules="required"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    v-model="itemPTU.fecha_final"
                                                    :valorDefecto="itemPTU.fecha_final"
                                                    label="Fecha final"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinal"
                                                    rules="required"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Dias de pago" rules="required|numeros_positivos">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Dias de pago" 
                                                        class="pa-0 ma-0" 
                                                        disabled
                                                        v-model="itemPTU.dias_de_pago" 
                                                        :error-messages="errors" 
                                                        required
                                                        oninput="if(this.value > 999 || this.value<0) this.value = 999; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    >                                                    
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-end card_accion">
                                        <button
                                            class="btnCerrar"
                                            @click="cerrarModalEditar()" 
                                            :disabled="isSavingEditar"
                                        >
                                            Cancelar
                                        </button>
                                        <v-btn
                                            class="btnGuardar"
                                            @click="editarPTU()" 
                                            :disabled="invalid" 
                                            :loading="isSavingEditar"
                                        >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </ValidationObserver>
                            </v-card>
                        </perfect-scrollbar>
                </v-dialog>
            </template> 
            
            
            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Timbres requeridos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                    <th class="errorth">Requeridos</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresRP.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresRP">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>
<script>
import Vue from 'vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Datatable from '@/components/datatable/Datatable';
import ExpandableItem from '@/components/ExpandableItemPTU.vue';
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import apiPuestos from "@/api/nominas/puestos";
import apiPTU from "@/api/nominas/ptu";
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import Notify from '@/plugins/notify';
import ExpandableItemEmpleado from '@/components/ExpandableItemPTUEmpleado.vue';
import moment from 'moment';



export default {
    components: {
        VuetifyMoneyValidate,
        VuetifyDatePicker,
        ExpandableItem,
        "data-table": Datatable,
        ExpandableItemEmpleado
    },
    data() {
        return {
            url                         : 'calculo-nomina/listado',
            rol                         : null,
            datosLogin                  : null,
            clientes                    : [],
            cliente_value               : null,
            empresas                    : [],
            empresa_value               : null,
            fraseLoading                : '',
            itemsAnios                  : [],
            anio_id                     : null,
            loadingLottie               : false,
            tab                         : 0,
            isLoadingConsultar          : false,
            topeIngreso                 : 0,
            importeRepartir             : 0,
            fechaPago                   : null,
            itemsPuestos                : [],
            arrayPuestos                : [],
            soloAcumulado               : false,
            dias_minimo_laborados       : 60,
            empleados_menor_60_dias     : true,
            incluir_vacaciones_pagadas  : false,
            topar_ptu                   : true,
            ptuEmpresa                  : null,
            ptuAnio                     : null,
            ptuTope                     : 0,
            ptuImporte                  : 0,
            overlayCargar               : false,
            showDatos                   : false,
            isSaving                    : false,
            btnGuardar                  : true,
            itemsNominas                : [],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                acumulado : false,
                empresa_id: null,
                tipo_ptu: true,
            },
            columns             : [
                {
                    label: 'Tipo de nómina',
                    name: 'tipo_nomina',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'periodo',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fechaPago',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            itemsTablaNominas   : [
                {
                    codigo:'Q005',
                    descripcion:'Q001 Cliker',
                    periodo: '01',
                    fechaPago: '2022-05-03'
                }
            ],
            isLoadingTabla      : false,
            options: {
                length: 11,
                locale: "es-MX",
                precision: 2,
                prefix: "$",
                suffix: "",
            },
            dialogTimbre                : false,
            imagen                      : '',
            tituloModal                 : null,
            itemPrecalculo              : null,
            dialogRecalcular            : false,
            dialogReconsulta            : false,
            isLoadingReconsulta         : false,
            nombreEmpresa               : null,
            totalPercepciones           : null,
            totalDeducciones            : null,
            neto                        : null,
            itemsEmpleadosReconsulta    : null,
            dialogEditar                : false,
            isLoadingEditar             : false,
            meses:[ 
                {nombre:'Enero',     id:0},
                {nombre:'Febrero',   id:1}, 
                {nombre:'Marzo',     id:2}, 
                {nombre:'Abril',     id:3}, 
                {nombre:'Mayo',      id:4}, 
                {nombre:'Junio',     id:5},
                {nombre:'Julio',     id:6},
                {nombre:'Agosto',    id:7}, 
                {nombre:'Septiembre',id:8}, 
                {nombre:'Octubre',   id:9}, 
                {nombre:'Noviembre', id:10}, 
                {nombre:'Diciembre', id:11}
            ],
            mes_de_acomulacion: null,
            fecha_de_pago: null,
            isSavingEditar: false,
            itemPTU:{
                periodo_id:null,
                fecha_de_pago: null,
                fecha_final: null,
                fecha_inicial: null,
                dias_de_pago: null
            },
            dialogErrores           : false,
            erroresRP               : [],
            isLoading               : false,
            tipo_complemento : null,
            itemsComplementos: [
                {nombre: "PTU" , value: "p_PTU" }, 
                {nombre: "PTU SMG" , value: "p_PTU_SMG"}
            ],
        }
    },
    computed: {
        likesAllPuestos () {
            return this.arrayPuestos.length === this.itemsPuestos.length
        },
        likesSomePuestos () {
            return this.arrayPuestos.length > 0 && !this.likesAllPuestos
        },
        icon () {
            if (this.likesAllPuestos) return 'mdi-close-box'
            if (this.likesSomePuestos) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        cliente_value: function(val, oldVal){
            this.btnGuardar = true;
            if(oldVal!=null && val!=oldVal){
                this.empresa_value = null;
            }
            if(val!= null){
                this.getEmpresas(val);
            }
        },
        empresa_value: function(val, oldVal){
            this.btnGuardar = true;
            if(oldVal!=null && val!=oldVal){
                this.anio_id = null;
                this.arrayPuestos = [];
            }
            if(val!= null){
                this.getAnios();
                this.getPuestos(val);
            }
        },
        anio_id: function(val, oldVal){
            this.btnGuardar = true;
        },
        topeIngreso: function(val, oldVal){
            this.btnGuardar = true;
        },
        importeRepartir: function(val, oldVal){
            this.btnGuardar = true;
        },
        fechaPago:function(val, oldVal){
            this.btnGuardar = true;
        },
        tipo_complemento:function(val, oldVal){
            this.btnGuardar = true;
        },
        soloAcumulado:function(val,olVal){
            this.btnGuardar = true;
        },
        incluir_vacaciones_pagadas: function(val, oldVal){
            this.btnGuardar = true;
        },
        topar_ptu:function(val, oldVal){
            this.btnGuardar = true;
        },
        empleados_menor_60_dias: function(val, oldVal){
            this.btnGuardar = true;
        },
        dias_minimo_laborados: function(val, oldVal){
            this.btnGuardar = true;
        },
        arrayPuestos: function(val, oldVal){
            this.btnGuardar = true;
        },
        tab:function(value){
            
            if(value == 1){
                this.getNominas();
            }
        },
        itemPTU:{
            deep: true,
            handler(periodo){
                
                if( periodo.fecha_inicial != '' && periodo.fecha_final != '' && 
                    periodo.fecha_inicial != null && periodo.fecha_final != null && 
                    periodo.fecha_inicial.length == 10 && periodo.fecha_final.length == 10 
                ){
                    if((moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 ) > 0){
                        console.log("Entre aqui")
                        periodo.dias_de_pago = (moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 );
                    }
                    else{
                        Notify.ErrorToast("El rango de fechas es incorrecto.");
                        periodo.dias_de_pago = null;
                    }
                }

                
            }
        },
    },
    methods: {
        /**
         * @method consultar Método para realizar consulta PTU
         * @description Este método realiza petición a la API de consultar el PTU
         */
        async consultar(){
            let parametros = { 
                accion                      : 'CONSULTAR',
                empresa_id                  : this.empresa_value,
                anio_fiscal_id              : this.anio_id,
                tope_ingreso_anual          : this.topeIngreso == 0 ? null:this.topeIngreso,
                importe_repartir            : this.importeRepartir,
                fecha_pago                  : this.fechaPago,
                tipo_complemento            : this.tipo_complemento,
                solo_acumulados             : this.soloAcumulado,
                incluir_vacaciones_pagadas  : this.incluir_vacaciones_pagadas ? false:true,
                topar_ptu                   : this.topar_ptu,
                empleados_menor_60_dias     : this.empleados_menor_60_dias,
                dias_minimo_laborados       : this.dias_minimo_laborados,
                descargar                   : false,
                puestos_excluidos           : this.arrayPuestos,
            }

            this.isLoadingConsultar = true;
            await apiPTU.consultarPTU(parametros).then(async (response) => {
                if(response.tipos_nominas_data.length > 0){
                    this.showDatos = true;
                    this.btnGuardar = false;
                    this.itemsNominas       = response.tipos_nominas_data;
                    let empresa             = await this.empresas.find(element => element.id == this.empresa_value);
                    this.ptuEmpresa         = empresa.nombre;
                    let anio                = await this.itemsAnios.find(element => element.id == this.anio_id);
                    this.ptuAnio            = anio.descripcion;
                    this.ptuTope            = this.topeIngreso;
                    this.ptuImporte         = this.importeRepartir;
                    this.showDatos          = true;
                }
                if(response.tipos_nominas_data.length == 0){
                    Notify.ErrorToast('No hay elementos para mostrar');
                    this.ptuEmpresa         = null;
                    this.ptuAnio            = null;
                    this.ptuTope            = 0;
                    this.ptuImporte         = 0;
                    this.showDatos          = false;
                }
                this.isLoadingConsultar = false;

            })
            .catch(err => {
                this.isLoadingConsultar = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            }); 
        },

        /**
         * @method reporteConsulta Método para descargar la consulta en formato Excel.
         * @description Este método realiza consulta a la API de consulta pero se le pasa el valor "descargar:true", para poder descargar un archivo Excel.
         */
        reporteConsulta(tipo = 'xlsx'){
            let parametros = { 
                accion                      : 'CONSULTAR',
                empresa_id                  : this.empresa_value,
                anio_fiscal_id              : this.anio_id,
                tope_ingreso_anual          : this.topeIngreso == 0 ? null:this.topeIngreso,
                importe_repartir            : this.importeRepartir,
                fecha_pago                  : this.fechaPago,
                tipo_complemento            : this.tipo_complemento,
                solo_acumulados             : this.soloAcumulado,
                incluir_vacaciones_pagadas  : this.incluir_vacaciones_pagadas ? false:true,
                topar_ptu                   : this.topar_ptu,
                empleados_menor_60_dias     : this.empleados_menor_60_dias,
                dias_minimo_laborados       : this.dias_minimo_laborados,
                descargar                   : true,
                puestos_excluidos           : this.arrayPuestos,
            }
            this.loadingLottie = true;
            this.fraseLoading = 'Descargando';
            const FileDownload = require("js-file-download");
            let nombre;
            nombre = "ReportePTU."+tipo;
            let url = "calculo-nomina/calcular-ptu";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },

        /**
         * @method guardar Método para guardar la cunsulta realizada
         * @description Este método sirve para guardar la consulta, al mismo tiempo realiza la descarga de la consulta.
         */
        guardar(tipo = 'xlsx'){
            let parametros = { 
                accion                      : 'CALCULAR',
                empresa_id                  : this.empresa_value,
                anio_fiscal_id              : this.anio_id,
                tope_ingreso_anual          : this.topeIngreso == 0 ? null:this.topeIngreso,
                importe_repartir            : this.importeRepartir,
                fecha_pago                  : this.fechaPago,
                tipo_complemento            : this.tipo_complemento,
                solo_acumulados             : this.soloAcumulado,
                incluir_vacaciones_pagadas  : this.incluir_vacaciones_pagadas ? false:true,
                topar_ptu                   : this.topar_ptu,
                empleados_menor_60_dias     : this.empleados_menor_60_dias,
                dias_minimo_laborados       : this.dias_minimo_laborados,
                descargar                   : true,
                puestos_excluidos           : this.arrayPuestos,
            }
            this.loadingLottie = true;
            this.fraseLoading = 'Guardando';
            const FileDownload = require("js-file-download");
            let nombre;
            nombre = "ReportePTU."+tipo;
            let url = "calculo-nomina/calcular-ptu";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then(async (response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                    await this.resetearValores();
                    this.tab = 1;
                })
                .catch(async (error)=>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },

        /**
         * @method eliminar Método para eliminar un registro de calculo de PTU.
         * @description Esté método realiza la petición a la API de eliminación de un registro de PTU.
         */
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el cálculo de PTU " + data.clave_tipo_nomina + " - " + data.descripcion + "?",
                "Eliminar",
                () => {
                    apiCalculoNomina.delete(data.id).then(response => {
                        Notify.Success("Cálculo de PTU eliminado", "El cálculo de PTU ha sido eliminado satisfactoriamente");
                        this.$refs.tablaNomina.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        /**
         * @method abrirModalTimbre Método para abrir modal de acumulación/timbre
         * @description Esté método sirve para abrir un modal para realizar la acción de acumulación o timbrado.
         */
        abrirModalTimbre(item){
            this.imagen = "/static/icon/alert_warning.png";
            this.tituloModal = "ADVERTENCIA"
            this.dialogTimbre =  true;
            this.itemPrecalculo = item;
        },

        /**
         * @method acumular Método para acumular/timbrar registro PTU
         * @description Este método realiza la petición a la API de acumulación, en caso de dar click en el boton timbrar,
         * primero realizara la petición a la API de acumuación y en caso de tener una respuesta correcta se realizara la 
         * petición a la API de timbrado.
         */
        async acumular(accion){
            this.loadingLottie = true;
            this.fraseLoading = "Acumulando";
            let self = this;
            if (accion == 'acumular'){
                let param = {nomina_id:self.itemPrecalculo.id};
                apiCalculoNomina.acumular(param).then(async response => {
                    Notify.Success("Acumulado de nómina", "El cálculo de nómina ha sido acumulado correctamente."); 
                    this.loadingLottie = false;
                    self.$refs.tablaNomina.getData();
                    self.cerrarModal();
                })
                .catch(err => {
                    this.loadingLottie = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        self.cerrarModal();
                    }
                });
            }
            else if (accion == 'timbrar'){
                let param = {nomina_id:self.itemPrecalculo.id};
                apiCalculoNomina.acumularTimbrar(param).then(async response => {
                    
                    if(response.status == 200){
                        self.detalleTimbre(self.itemPrecalculo);
                        self.$refs.tablaNomina.getData();
                    }
                    else if(response.status == 202){
                        self.isLoading =false;
                        self.erroresRP = response.data.items;
                        self.dialogErrores = true;
                    }
                })
                .catch(err => {
                    this.loadingLottie = false;
                    this.isLoading =false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
        },

        /**
         * @method timbrar Método para timbrar
         * @description Este método realiza la petición a la API de timbrado de nómina.
         */
        timbrar(data){
            this.fraseLoading = "Timbrando";
            this.loadingLottie = true;
            let param = {nomina_id:data.id};
            apiCalculoNomina.timbrar(param).then(response => {
                this.detalleTimbre(data);
            })
            .catch(err => {
                this.loadingLottie=false; 
                this.$refs.tablaNomina.getData();
                this.cerrarModal();
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });

        },

        /**
         * @method reporte Método para descargar reporte de un registro PTU
         * @description Método para descargar reporte de un registro de una empresa de PTU.
         */
        reporte(item){
            let parametros = { 
                accion              : 'RECONSULTAR',
                tipo_nomina         : 'PTU',
                descargar           : true,
                id                  : item.id
            }
            this.loadingLottie = true;
            this.fraseLoading = 'Descargando';
            const FileDownload = require("js-file-download");
            let nombre;
            let tipo = '.xlsx';
            nombre = "ReportePTU."+tipo;
            let url = "calculo-nomina/calcular-ptu";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },

        /**
         * @method abrirModalRecalcular Método para abrir el modal para recalcular el registro de PTU.
         * @description Este método abre un modal para realizar el recalculo del registro de PTU.
         */
        abrirModalRecalcular(item){
            this.dialogRecalcular = true;
            this.tituloModal = "Recalcular";
            this.itemPrecalculo = item;
        },

        /**
         * @method recalcular Método para recalcular un registro de PTU
         * @description Este método realiza una petición API para recalcular el registro de PTU y se descarga un archivo excel con los datos actualizados.
         */
        recalcular(){
            let parametros = { 
                accion              : 'RECALCULAR',
                tipo_nomina         : 'PTU',
                descargar           : true,
                id                  : this.itemPrecalculo.id
            }
            this.loadingLottie = true;
            this.fraseLoading = 'Guardando';
            const FileDownload = require("js-file-download");
            let nombre;
            let tipo = 'xlsx'
            nombre = "ReportePTU."+tipo;
            let url = "calculo-nomina/calcular-ptu";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then(async (response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                    this.$refs.tablaNomina.getData();
                    this.cerrarModal();
                })
                .catch(async (error)=>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        
        /**
         * @method getNominas Método para actilar los registros PTU.
         * @description Este método realiza la actualización de la tabla de os registros de PTU.
         * 
         */
        getNominas(){
            /* this.filters  = {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                acumulado : false,
                empresa_id: null,
                tipo_ptu: true,
            }; */

            if(this.$refs.tablaNomina){
                this.$refs.tablaNomina.getData();
            }
        },
        abrirModalBuscar(){



        },

        /**
         * @method cerrarModal Metodo para cerrar modal.
         * @description Este método sirve para cerrar el modal de acumulación/timbre o el de recalcular.
         */
        cerrarModal(){
            this.dialogTimbre = false;
            this.dialogRecalcular = false;   
            this.itemPrecalculo = null;
        },

        /**
         * @method resetearValores Método para resetear los valores.
         * @description Este método sirve para resetear los valor de los campos para la consulta de PTU.
         */
        resetearValores(){
            if(this.rol == 'root'){
                this.cliente_value = null;
                this.empresa_value = null;
            }
            else if (this.rol == 'admin'){
                this.empresa_value = null;
            }
            this.anio_id                    = null;
            this.topeIngreso                = null;
            this.importeRepartir            = null;
            this.fechaPago                  = null;
            this.tipo_complemento           = null;
            this.soloAcumulado              = false;
            this.incluir_vacaciones_pagadas = false;
            this.topar_ptu                  = true;
            this.empleados_menor_60_dias    = true;
            this.dias_minimo_laborados      = 60;
            this.itemsNominas               = [];
            this.itemsPuestos               = [];
            this.arrayPuestos               = [];
            this.ptuEmpresa                 = null;
            this.ptuAnio                    = null;
            this.ptuTope                    = 0;
            this.ptuImporte                 = 0;
            this.showDatos                  = false;

            if(this.$refs.FechaPago != undefined || this.$refs.FechaPago != null){
                this.$refs.FechaPago.resetDate();
            }

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },

        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes cuando el rol es root y el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method getEmpresas Este método realiza petición a la API de empresas.
         * @description Realiza petición a la API de empresas cuando el cliente es asigando y el resultado lo almacena en el array itemEmpresas[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getEmpresas(cliente_id) {
            if(cliente_id == null){
                return;
            }
            this.empresas = [];
            let param = { activo: true, paginate: false, cliente_id: cliente_id };
            await apiEmpresas.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method getPuestos Método que realiza petición a la API de puestos.
         * @description Este método petición a la API de puestos cuando la empresa es seleccionada y el resultado lo almacena en el array itemsPuestos[].
         * Se la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición
         */
        async getPuestos(empresa_id) {
            if(empresa_id == null){
                return;
            }
            this.itemsPuestos = [];
            let param = { activo: true, paginate: false, empresa_id: empresa_id };
            await apiPuestos.find(param).then(response => {
                this.itemsPuestos = response.data;
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },


        /**
         * @method getAnios Este método realiza petición a la API de años fiscales.
         * @description Realiza petición a la API de años fiscales cuando el valor del registro patronal es asigando y el resultado lo almacena en el array itemsAnios[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getAnios(){
            this.itemsAnios = [];
            let parametros = { activo:true, paginate:false, order:"desc", empresa_id: this.empresa_value }
            await apiPTU.findAnios(parametros).then((response) => {
                this.itemsAnios = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los años.");
            });
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @param {string} tipo string con valor 'modal' o 'tabla'
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
            else{
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }
            
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  

        /**
         * @method complementos Método para periodo de complementos
         * @description Este método realiza la petición a la API getPeriodoIncidencia, Se realiza la peticion que devuelve los datos del periodo de complementos;
         * en caso de que no exista un periodo de complentos se crea un periodo de complementos temporal. Si la petición es correcta se redirecciona a la vista de 
         * complementos de nómina.
         * @param {object} item Objeto con los valores del registro de PTU. 
         */
        async complementos(item){
            await apiPTU.getPeriodoIncidencia(item.periodo_id).then((response) => {
                if (response.length == 0){
                    Notify.ErrorToast("Ocurrió un error al realizar la petición.");
                    return;
                }
                else{
                    
                    response[0].tipo_periodo_especial = response[0].periodo_model.tipo_periodo_especial;
                    response[0].fecha_final = response[0].periodo_model.fecha_final;
                    response[0].fecha_inicial = response[0].periodo_model.fecha_inicial;
                    response[0].periodo_incidencia = response[0].id;
                    response[0].id = response[0].periodo_id;
                    this.$session.remove("periodoIncidencias");
                    this.$session.set('periodoIncidencias',response[0]);
                    this.$session.set('temporal',true);
                    this.$router.push('/incidenciasNominas');
                }

            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al realizar la petición.");
            });
        },

        /**
         * @method detallePTU Método para enviar a vista detalle de PTU.
         * @description Este método realiza la acción de enviar a la vista de PTU donde se listan los empleados que se encuentran en el registro de PTU.
         * @param {object} data Objeto con los valores del registro de PTU. 
         */
        detallePTU(data){
            this.$session.remove("detallePTU");
            this.$session.set('detallePTU',data);
            this.$router.push('/detallePTU');
        },

        /**
         * @method detalleTimbre Método para redirigir a la vista detalle de timbre.
         * @description Método que sirve para redirigir a la vista de detalle del timbrado, para que el usuario pueda ver el proceso de timbre.
         * @param {object} data Objeto con los valores del registro de PTU. 
         */
        detalleTimbre(data){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',data);
            this.$router.push('/detalleTimbre');
        },

        /**
         * @method abrirModalReconsultar Método que abre modal de reconsulta de una nómina PTU activa.
         * @description Método que abre modal de reconsulta de una nómina PTU activa. Se realiza el llamado al método reconsultar.
         */
        async abrirModalReconsultar(){
            this.dialogReconsulta = true;
            this.imagen = "/static/icon/consultar-calculo.png";
            this.tituloModal = "Reconsulta";
            await this.reconsultar();
        },

        /**
         * @method reconsultar Método que realiza petición a la API reconsultar
         * @description Método que realiza la petición a la API de reconsulta de una nómina de PTU activa.
         */
        async reconsultar(){
            this.isLoadingReconsulta = true;
            this.nombreEmpresa      = null;
            this.totalPercepciones  = 0;
            this.totalDeducciones   = 0;
            this.neto               = 0;
            let parametros = { 
                accion              : 'RECONSULTAR',
                tipo_nomina         : 'PTU',
                descargar           : false,
                id                  : this.itemPrecalculo.id,
            }
            await apiPTU.consultarPTU(parametros).then(async (response) => {
                if(response.data.length > 0){
                    this.nombreEmpresa      = this.itemPrecalculo.nombre_empresa;
                    this.totalPercepciones  = response.totales.percepciones;
                    this.totalDeducciones   = response.totales.deducciones;
                    this.neto               = response.totales.neto;
                    this.itemsEmpleadosReconsulta = response.data;
                }
                if(response.data.length == 0){
                    Notify.ErrorToast('No hay elementos para mostrar');
                    this.nombreEmpresa      = null;
                    this.totalPercepciones  = 0;
                    this.totalDeducciones   = 0;
                    this.neto               = 0;
                }
                this.isLoadingReconsulta = false;
            })
            .catch(err => {
                this.isLoadingConsultar = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            }); 
        },

        /**
         * @method cerrarModalReconsulta Método para cerrar el modal de reconsulta.
         * @description Método para cerrar el modal de reconsulta.
         */
        cerrarModalReconsulta(){
            this.dialogReconsulta   = false;
            this.isLoadingReconsulta = false;
            this.nombreEmpresa      = null;
            this.totalPercepciones  = 0;
            this.totalDeducciones   = 0;
            this.neto               = 0;
        },

        /**
         * @method toggleAllPuestos Método para seleccionar todos los puestos
         * @description Método para seleccionar todos los puestos en el dropdown.
         */
        toggleAllPuestos () {
            this.$nextTick(() => {
                if (this.likesAllPuestos) {
                    this.arrayPuestos = [];
                } else {
                    this.arrayPuestos = [];
                    if(this.itemsPuestos.length > 0 ){
                        for (let puesto = 0; puesto < this.itemsPuestos.length; puesto++) {
                            this.arrayPuestos.push(this.itemsPuestos[puesto].id);
                        }
                    }
                }
            })
        },

        /**
         * @method abrirModalEditar Método para abrir modal editar
         * @description Método para abrir el modal de editar.
         */
        abrirModalEditar(item){
            this.isLoadingEditar = true;
            this.dialogEditar = true;
            this.tituloModal = "Editar";
            this.itemPTU = item;
            this.mes_de_acomulacion = item.mes_de_acomulacion;
            this.fecha_de_pago = item.fecha_de_pago;

            this.isLoadingEditar = false;
        },
        cerrarModalEditar(){
            this.dialogEditar = false;
            this.isLoadingEditar = false;
            this.mes_de_acomulacion = null;
            this.fecha_de_pago = null;
            this.isSavingEditar = false;
            this.itemPTU={
                periodo_id:null,
                fecha_de_pago: null,
                fecha_final: null,
                fecha_inicial: null,
                dias_de_pago: null
            }
        },
        async editarPTU(){
            this.$refs.formEditar.validate().then(async success => {
                this.isSavingEditar = true;
                if(!success) {
                    this.isSavingEditar = false;
                    return;
                }

                let parametros = {
                    id:this.itemPTU.periodo_id, 
                    mes_de_acomulacion:this.mes_de_acomulacion, 
                    fecha_de_pago: this.fecha_de_pago,
                    fecha_inicial: this.itemPTU.fecha_inicial, 
                    fecha_final: this.itemPTU.fecha_final, 
                    dias_de_pago: this.itemPTU.dias_de_pago
                }
                
                await apiPTU.editarPTU(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "El PTU se actualizó satisfactoriamente.");
                    this.$refs.tablaNomina.getData();
                    this.cerrarModalEditar();
                })
                .catch(err => {
                    this.isSavingEditar = false;
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al actualizar el PTU.");
                });
            })
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        },
    },

    /**
     * @description created() ciclo de vida vue. En este apartado se inicializan variables antes de montar la vista
     */
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        if (this.rol == "root") {
            this.getClientes();
        }
        else{
            this.cliente_value = this.datosLogin.cliente_id;
        }

        if (this.rol != "root") {
            this.filters.cliente_id = this.cliente_value;
            if(this.rol == 'admin-empresa' || this.rol == 'admin-sucusal' || this.rol == 'admin-depto'){
                this.filters.empresa_id =  this.empresa_value = this.datosLogin.empresa_id;
            }
        }
    },

    /**
     * @description mounted() ciclo de de vida vue. En este apartado se realiza algunas condiciones al momento de montar la vista y componentes.
     */
    mounted() {
        if(sessionStorage.getItem('ptuActivas') == 'ptuActivas' && sessionStorage.getItem('rutaFrom') == 'IncidenciasNominas'){
            this.tab = 1
        }
    },
    
}
</script>
<style scoped>

    #btnModalTimbre{
        display: none;
    }

    #btnModalEditar{
        display: none;
    }
    .lineCliker{
        border: 3px solid #2DB9D1;
        background-color: #2DB9D1;
        border-radius: 10px;
        height: 100% !important;
        margin-left:20px;
        margin-right: 20px;
    }
    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
    }

    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralConsulta{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }
    .btnClass{
        width: 200px;
        background-color: cornflowerblue;
        height: 60px;
        border: none;
        color: #ffffff;
        font-size:30px;
        cursor:pointer
    }
    
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .menuClassAccionesItem{
        border-radius: 10px!important;
    }
    .trBody{
        border-width:1px; 
        border-color:#EFF1FB ;
        border-bottom-style: solid;
    }
    .titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    #btnModalRecalculo{
        display: none;
    }
    .tbl-header-reconsulta {
        background: #F6F6F6;
        height: 58px;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
    .th-reconsulta {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding-left: 50px;
        text-align: left !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
    .subtitle-divider{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .text-laborados{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        letter-spacing: 0.1px !important;
        color: #828282 !important;
    }
    .title-modal-editar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2245;
    }
    .informacion-general-titulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
        margin-left: 12px;
    }
    .menu-width{
        max-width: 500px;
        border-radius: 20px !important;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    .v-btn-periodo:hover:before {
      background-color: transparent;
      
    }
    .v-btn-periodo:before {
        display: none;
    }
    .span-periodo{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        text-decoration-line: underline;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    #btnModalErrores{
        display: none;
    }
</style>