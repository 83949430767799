<template>
    <div class="pa-0 ma-0">
        <tr>
            <td>
                <v-list class="py-0 my-0" style="background:transparent;">
                    <v-list-item class="py-0 my-0 px-0" style="background:transparent;">
                        <v-list-item-avatar class="py-0 my-0" style="background:transparent;">
                            <v-img class="py-0 my-0" :src="urlImg+item.empleado.foto"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="ml-0 py-0 my-0" style="background:transparent;">
                            <v-list-item-title class="tblPrincipal" style="background:transparent;">{{ item.empleado.nombre_completo }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </td>
            <td style="min-width:79px!important; max-width:81px!important; width:80px; padding-left:8px; padding-right:30px;">
                <div class="tblOpciones">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                        </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>
        <template v-if="isOpen">
            <table width="100%">
                <tbody class="bodyInfo">
                    <tr>
                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Información</td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Fecha de alta: </p></td>
                        <td><p class="text">{{dateFormat(item.empleado.fecha_alta_imss, 'modal')}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Sueldo diario: </p></td>
                        <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Días laborados del año: </p></td>
                        <td><p class="text">{{item.dias_anio_laborado}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Sueldo del año: </p></td>
                        <td><p class="text">{{formatNum(item.ingreso_anual)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Puesto: </p></td>
                        <td><p class="text">{{item.empleado.puesto}}</p></td>
                    </tr>
                    
                    <template>
                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                        <tr>
                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                        </tr>
                        <tr v-for="itemIn in itemsPercepciones" :key="'P'+itemIn.id">
                            <td><p class="subtitle">{{itemIn.nombre_incidencia}}</p></td>
                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                        </tr>
                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                    </template>

                    <template>
                        <tr>
                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                        </tr>
                        <tr>
                            <td><p class="subtitle">ISR:</p></td>
                            <td><p class="text">{{formatNum(item.isr_retener)}}</p></td>
                        </tr>
                        <tr v-for="itemIn in itemsDeducciones" :key="'D'+itemIn.id">
                            
                            <td><p class="subtitle">{{itemIn.nombre_incidencia}}</p></td>
                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                        </tr>
                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                    </template>
                    <template v-if="itemsOtrosPagos.length != 0">
                        <tr v-if="itemsDeducciones.length == 0 && itemsPercepciones.length == 0"><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                        <tr>
                            <td class="pb-4" style="color: #1E2245; font-weight: bold;">Otros pagos:</td>
                        </tr>
                        <tr v-for="itemIn in itemsOtrosPagos" :key="itemIn.id">
                            <td><p class="subtitle">{{itemIn.nombre_incidencia}}</p></td>
                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                        </tr>
                        <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                    </template>

                    <tr>
                        <td><p class="subtitle">Total percepciones: </p></td>
                        <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Total deducciones: </p></td>
                        <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Total otros pagos: </p></td>
                        <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Percepciones exentas: </p></td>
                        <td><p class="text">{{formatNum(item.per_exento)}}</p></td>
                    </tr>
                    <tr>
                        <td><p class="subtitle">Percepciones gravadas: </p></td>
                        <td><p class="text">{{formatNum(item.per_gravado)}}</p></td>
                    </tr>
                    <tr>
                        <td class="py-4" style="color: #1E2245; font-weight: 600;">Neto a recibir: </td>
                        <td class="py-4" style="color: #1E2245; font-weight: 600;">{{formatNum(item.neto_pagar)}}</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>


<script>
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    name: 'expandable-item',
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
    },
    data() {
        return {
            isOpen: false,
            urlImg : '',
        }
    },
    computed: {
        itemsPercepciones(){
            let itemsPercepciones   = [];

            this.item.incidencias.forEach(incidencia =>{
                switch (incidencia.codigo_incidencia.charAt(0)) {
                    case 'P':
                            let complemento = {
                                codigo : incidencia.codigo_incidencia,
                                nombre_incidencia : incidencia.nombre_incidencia,
                                total  : incidencia.total
                            }
                            if(parseFloat(incidencia.total) != 0){
                                itemsPercepciones.push(complemento);
                            }
                        break;
                    default:
                        break;
                }  
            })
            return itemsPercepciones;
        },
        itemsDeducciones(){
            let itemsDeducciones   = [];

            this.item.incidencias.forEach(incidencia =>{
                switch (incidencia.codigo_incidencia.charAt(0)) {
                    case 'D':
                            let complemento = {
                                codigo : incidencia.codigo_incidencia,
                                nombre_incidencia : incidencia.nombre_incidencia,
                                total  : incidencia.total
                            }
                            if(parseFloat(incidencia.total) != 0){
                                itemsDeducciones.push(complemento);
                            }
                        break;
                    default:
                        break;
                }  
            })
            return itemsDeducciones;
        },
        itemsOtrosPagos(){
            let itemsOtrosPagos   = [];

            this.item.incidencias.forEach(incidencia =>{
                switch (incidencia.codigo_incidencia.charAt(0)) {
                    case 'O':
                            let complemento = {
                                codigo : incidencia.codigo_incidencia,
                                nombre_incidencia : incidencia.nombre_incidencia,
                                total  : incidencia.total
                            }
                            if(parseFloat(incidencia.total) != 0){
                                itemsOtrosPagos.push(complemento);
                            }
                        break;
                    default:
                        break;
                }  
            })
            return itemsOtrosPagos;
        }
    },
    methods: {
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
    },
    mounted () {
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    /**
    * TRANSITION EFFECT
    */
    .slide-enter-active,
    .slide-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-enter,
    .slide-leave-active {
        opacity: 0
    }

    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #F9F9F9;
        padding: 2px 45px 2px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }
    td > .loading{
        max-width: 100% !important;
    }
    tr {
      display:table;
      table-layout:fixed;
      background-color: #F9F9F9;
      width: 100%;
    }
    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
    }

    tbody > div {
        vertical-align: middle !important;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .bodyInfo tr {
        background-color: #FFFFFF;
    }
    .bodyInfo tr td:first-child{
        padding-left: 100px !important;
    }
    .bodyInfo tr td{
        background-color: #FFFFFF;
    }


    
</style>